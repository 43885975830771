module.exports = [{
      plugin: require('/Users/claudiotx-air18/dev/claudioteixeira.com/node_modules/gatsby-plugin-emotion/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/claudiotx-air18/dev/claudioteixeira.com/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/claudiotx-air18/dev/claudioteixeira.com/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/claudiotx-air18/dev/claudioteixeira.com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
